import styled from "@emotion/styled";
import { Switch } from "antd";
import { colors } from "theme";

export const MySwitchContainer = styled(Switch)`
  min-width: 63px;
  height: 32px;
  background-color: ${colors.gray};
  &.ant-switch-checked {
    background-color: #60c63a;
    .ant-switch-handle {
      left: calc(100% - 18px - 11px);
    }
    .ant-switch-inner {
      margin: 0 35px 0 7px;
    }
  }
  .ant-switch-handle {
    width: 27px;
    height: 27px;
    &::before {
      border-radius: 50%;
    }
  }
  .ant-switch-inner {
    margin: 0 7px 0 35px;
  }
`;
